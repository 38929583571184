<template>
  <div>
    <b-modal
      v-model="isLoginModalActive"
      has-modal-card
      trap-focus
      class="px-5"
      @close="$emit('close')"
    >
      <template #default>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Anmelden</p>
          </header>
          <section class="modal-card-body">
            <b-message type="is-danger" v-if="error">
              E-Mailadresse oder Passwort falsch.
            </b-message>
            <p class="mb-2" v-if="contentText != null">
              {{ contentText }}
            </p>
            <p>
                <b-button type="is-ghost" @click="isRegisterModalActive = true; $emit('close');" expanded>Noch kein Kundenkonto?</b-button>
            </p>
            <b-field label="E-Mailadresse">
              <b-input v-model="loginUsername" type="mail" />
            </b-field>
            <b-field label="Passwort">
              <b-input v-model="loginPassword" type="password" />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <b-button
              label="Anmelden"
              type="is-primary"
              @click="login"
              :loading="isLoading"
            />
            <b-button
              label="Abbrechen"
              type="is-light"
              @click="$emit('close')"
              :loading="isLoading"
            ></b-button>
          </footer>
        </div>
      </template>
    </b-modal>
    <register-modal :isRegisterModalActive="isRegisterModalActive" :redirectUrl="redirectUrl" @close="isRegisterModalActive = false; $emit('close')" />
  </div>
</template>

<script>
import RegisterModal from './RegisterModal.vue';
export default {
  components: {RegisterModal},
  props: {
    isLoginModalActive: {
      type: Boolean,
      default: false,
    },
    contentText: {
      type: String,
      default: null,
    },
    redirectUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      loginUsername: null,
      loginPassword: null,
      isRegisterModalActive: false
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
    loggedout() {
      return this.$store.state.authentication.status.loggedout;
    },
    error() {
      return this.$store.state.authentication.status.error;
    },
  },
  methods: {
    login() {
      const { dispatch } = this.$store;
      if (this.loginUsername && this.loginPassword) {
        dispatch("authentication/login", {
          username: this.loginUsername,
          password: this.loginPassword,
          redirectUrl: this.redirectUrl,
        });
      }
    },
  },
  watch: {
    '$store.getters.user': function() {
      if(this.$router.currentRoute.path == this.redirectUrl){
        if(this.$store.getters.user != null){
          this.$emit("close");
        }
      }
    }
  },
  mounted() {},
};
</script>
