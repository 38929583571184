<template>
  <section>
    <b-loading :active="isLoading" />
    <div class="container">
      <div class="has-text-centered">
        <div class="has-text-success" style="font-size: 5rem;">
          <b-icon pack="fas" icon="circle-check" />
        </div>
        <div>
          <p>Die Aufladung war erfolgreich.</p>
        </div>
      </div>
      <div class="balance has-text-centered mt-5" v-if="card">
        <div class="is-size-3">Neues Guthaben:</div>
        <div class="is-size-1 has-text-weight-bold has-text-black">
          {{ Number(card.balance).toFixed(2) }} &euro;
        </div>
      </div>
      <div class="has-text-centered mt-5">
        <router-link :to="'/card/' + cardCode" class="button mb-4">Zurück</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: true,
      cardCode: this.$route.params.id,
      card: null,
    };
  },
  methods: {
    init() {
      this.loadCardDetails();
    },
    loadCardDetails() {
      this.isLoading = true;

      this.$store.getters.axios
        .get(this.$store.getters.apiUrl + "/card/" + this.cardCode)
        .then((response) => {
          if (response.status == 204) {
            this.cardStatus = 1;
            return;
          }

          this.card = response.data;
          this.cardStatus = this.card.card_status.id;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
