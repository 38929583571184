<template>
  <b-modal
    v-model="isQRCodeModalActive"
    has-modal-card
    trap-focus
    class="px-5"
    @close="$emit('close')"
  >
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Deine <iec-card-logo /></p>
        </header>
        <section class="modal-card-body">
          <figure class="image">
            <qr-code
              :text="qrCodeData"
              style="max-width: 50vw; margin: 0 auto"
            />
          </figure>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Schließen"
            type="is-light"
            @click="$emit('close')"
          ></b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
import IecCardLogo from "../IecCardLogo.vue";
export default {
  name: "QRCodeModal",
  components: { IecCardLogo },
  props: {
    isQRCodeModalActive: {
      type: Boolean,
      default: false,
    },
    qrCodeData: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>
