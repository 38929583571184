<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-image">
          <figure class="image">
            <img :src="bonus.header_image_url" />
          </figure>
        </div>
        <div class="card-content">
          <p class="mb-1">
            <strong>{{ bonus.name }}</strong>
          </p>
          <p class="mb-1" v-html="bonus.description"></p>
        </div>
        <footer class="card-footer">
          <a
            href="#"
            class="card-footer-item"
            :disabled="true"
            v-on:click.prevent.stop="isRedeemBonusModalActive = true"
            >Jetzt einlösen</a
          >
          <p class="card-footer-item">
            <span> {{ bonus.points }} Punkte </span>
          </p>
        </footer>
      </div>
    </div>
    <redeem-bonus-modal :bonus="bonus" :isRedeemBonusModalActive="isRedeemBonusModalActive" @close="isRedeemBonusModalActive = false" @success="successfull_redemption()" />
  </div>
</template>

<script>
import RedeemBonusModal from './RedeemBonusModal.vue';
export default {
  name: "BonusCard",
  components: {RedeemBonusModal},
  data() {
    return {
      isLoading: false,
      isRedeemBonusModalActive: false
    };
  },
  props: {
    bonus: {
      type: Object,
      default: null,
    },
  },
  methods: {
    init() {},
    successfull_redemption(data){      
      this.$emit('success', data);
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
