<template>
  <div>
    <section class="mt-3">
      <div class="container">
        <div class="columns is-justify-content-center">
          <div class="column is-4">
            <iec-card :cardCode="cardCode" class="mb-4"></iec-card>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <f-a-q />
    </div>
  </div>
</template>

<script>
import FAQ from "./FAQ.vue";
import IecCard from "./IecCard.vue";
export default {
  name: "Home",
  components: { IecCard, FAQ },
  data() {
    return {
      isLoading: false,
      cardCode: this.$route.params.id,
    };
  },
  methods: {
    init() {},
  },
  mounted() {
    this.init();
  },
};
</script>

<style></style>
