<template>
  <div>
    <h3>Keine Karte in URL</h3>
  </div>
</template>

<script>
export default {
  name: "NoCardHome",
  components: {},
};
</script>

<style></style>
