<template>
  <section class="mt-3">
    <a href="https://shop.ticketingsolutions.de/7-iserlohn-roosters/privacy" target="_blank">Zur Datenschutzerklärung</a>
  </section>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style></style>
