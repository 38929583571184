<template>
    <section class="mt-3">
      <div class="container">      
        <router-link :to="'/transactions/' + cardCode" class="button mb-4">Zurück</router-link>   
        <h3 class="has-text-black mb-4">Deine gekauften Artikel</h3>
        <b-table :data="payment.items" :loading="isLoading">      
            <b-table-column label="Name" v-slot="props"
          >{{ props.row.name }}</b-table-column
        > 
        <b-table-column label="Stückpreis" v-slot="props" numeric
          >{{ Number(props.row.price).toFixed(2) }} &euro;</b-table-column
        >  
        <b-table-column label="Anzahl" v-slot="props" numeric
          >{{ Number(props.row.amount) }}</b-table-column
        >        
        <b-table-column label="Gesamtpreis" v-slot="props" numeric
          >{{ Number(props.row.total_price).toFixed(2) }} &euro;</b-table-column
        >     
        <template #empty>
          <div class="has-text-centered is-size-1">
            <b-icon pack="fas" icon="cart-circle-xmark" />
          </div>
          <p class="has-text-centered">Diese Buchung beinhaltet keine Artikel.</p>
        </template>
        </b-table>
        <div v-if="payment.vouchers.length > 0">
        <h3 class="has-text-black mb-4 mt-4">Berücksichtigte Rabatte</h3>
        <b-table :data="payment.vouchers" :loading="isLoading">      
            <b-table-column label="Name" v-slot="props"
          >{{ props.row.name }}</b-table-column
        >         
        <b-table-column label="Gesamtpreis" v-slot="props" numeric
          >- {{ Number(props.row.total_price).toFixed(2) }} &euro;</b-table-column
        >     
        </b-table>
      </div>
      </div>
    </section>
  </template>
  
  <script>
  import { dateMixin } from "../mixins/DateMixin.js";  
  
  export default {
    name: "TransactionDetail",
    mixins: [dateMixin],
    components: {  },
    data() {
      return {
        cardCode: this.$route.params.id,
        paymentId: this.$route.params.paymentId,
        payment: { items: [], vouchers: [] },
        isLoading: true,
      };
    },
    methods: {
      init() {
        this.isLoading = true;
  
        this.$store.getters.axios
          .get(
            this.$store.getters.apiUrl +
              "/card/" +
              this.cardCode +
              "/transactions/" + this.paymentId
          )
          .then((response) => {
            console.log(response);
            this.payment = response.data;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    mounted() {
      this.init();
    },
  };
  </script>
  
  <style></style>
  