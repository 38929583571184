import moment from 'moment-timezone'

export const dateMixin = {
  methods: {
    formatDate: function (date) {
      if (date === '-') {
        return date
      }
      return moment(date)
        .locale('de')
        .format('L')
    },
    formatDateTime: function (date) {
      if (date == null) {
        return '-'
      }
      return moment(date)
        .locale('de')
        .format('L LT')
    },
    getInLocalTimezone: function (date) {
      let dateUtc = moment.tz(date, 'UTC')
      return dateUtc.tz('Europe/Berlin').locale('de').format('L LT')
    }
  }
}
