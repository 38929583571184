<template>
  <b-modal
    v-model="isRegisterModalActive"
    has-modal-card
    trap-focus
    class="px-5"
    @close="$emit('close')"
  >
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Kundenkonto erstellen</p>
        </header>
        <section class="modal-card-body">
          <b-message type="is-danger" v-if="error">
            {{ error }}
          </b-message>
          <b-field label="Vorname">
            <b-input v-model="registerFirstName" />
          </b-field>
          <b-field label="Nachname">
            <b-input v-model="registerLastName" />
          </b-field>
          <b-field label="E-Mailadresse">
            <b-input v-model="registerEmail" type="mail" />
          </b-field>
          <b-field label="Passwort">
            <b-input v-model="registerPassword" type="password" />
          </b-field>
          <b-field label="Passwort wiederholen">
            <b-input v-model="registerRepeatPassword" type="password" />
          </b-field>
          <b-field label="Straße und Hausnummer">
            <b-input v-model="registerStreet" />
          </b-field>
          <b-field label="PLZ">
            <b-input v-model="registerZipCode" />
          </b-field>
          <b-field label="Stadt">
            <b-input v-model="registerCity" />
          </b-field>
          <b-field label="Land">
            <b-select v-model="registerCountry" expanded>
              <option
                v-for="country in countries"
                :value="country.code"
                :key="country.code"
              >
                {{ country.name }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Registrieren"
            type="is-primary"
            @click="register"
            :loading="isLoading"
          />
          <b-button
            label="Abbrechen"
            type="is-light"
            @click="$emit('close')"
            :loading="isLoading"
          ></b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  components: {},
  props: {
    isRegisterModalActive: {
      type: Boolean,
      default: false,
    },
    redirectUrl: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      registerEmail: "",
      registerPassword: "",
      registerRepeatPassword: "",
      registerFirstName: "",
      registerLastName: "",
      registerStreet: "",
      registerZipCode: "",
      registerCity: "",
      registerCountry: "DE",
      error: null,
      countries: [
        {
          name: "Deutschland",
          code: "DE",
        },
      ],
    };
  },
  methods: {
    register() {
      this.isLoading = true;
      this.error = null;

      if (
        this.registerEmail == "" ||
        this.registerPassword == "" ||
        this.registerRepeatPassword == "" ||
        this.registerFirstName == "" ||
        this.registerLastName == "" ||
        this.registerStreet == "" ||
        this.registerZipCode == "" ||
        this.registerCity == ""
      ) {
        this.error = "Bitte fülle alle Felder aus.";
        this.isLoading = false;
        return;
      }

      if (this.registerPassword != this.registerRepeatPassword) {
        this.error = "Die eingegebenen Passwörter stimmen nicht überein.";
        this.isLoading = false;
        return;
      }

      const payload = {
        email: this.registerEmail,
        password: this.registerPassword,
        first_name: this.registerFirstName,
        last_name: this.registerLastName,
        street: this.registerStreet,
        zip_code: this.registerZipCode,
        city: this.registerCity,
        country: this.registerCountry,
      };

      this.$store.getters.axios
        .post(this.$store.getters.apiUrl + "/register", payload)
        .then(() => {
          const { dispatch } = this.$store;
          dispatch("authentication/login", {
            username: this.registerEmail,
            password: this.registerPassword,
          });
        })
        .catch((error) => {
          if (error.response.data == "user_already_exists") {
            this.error = "Diese E-Mailadresse wird bereits verwendet.";
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {},
};
</script>
