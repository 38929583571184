<template>
  <b-modal
    v-model="isRedeemBonusModalActive"
    has-modal-card
    trap-focus
    class="px-5"
    @close="$emit('close')"
  >
    <template #default>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Prämie einlösen</p>
        </header>
        <div class="modal-card-image">
          <figure class="image">
            <img :src="bonus.header_image_url" />
          </figure>
        </div>
        <section class="modal-card-body">
          <div v-if="redeemBonusResult == null">
            <p class="mb-4">
              <strong>{{ bonus.name }}</strong>
            </p>
            <p class="mb-4" v-html="bonus.description"></p>
            <p>
              <b-checkbox v-model="redeemCheck" class="mb-4">
                Ich bin mir sicher, dass ich diese Prämie erhalten möchte.
              </b-checkbox>
            </p>
          </div>
          <div v-else>
            <p v-html="redeemBonusResult.message"></p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <b-button
            :loading="isLoading"
            label="Jetzt einlösen"
            type="is-primary"
            @click="redeemBonus"
            :disabled="!redeemCheck"
            v-if="redeemBonusResult == null"
          ></b-button>
          <b-button
            :loading="isLoading"
            label="Abbrechen"
            type="is-light"
            v-if="redeemBonusResult == null"
            @click="closeModal"
          ></b-button>
          <b-button
            :loading="isLoading"
            label="Schließen"
            type="is-light"
            v-if="redeemBonusResult != null"
            @click="closeModal"
          ></b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "RedeemBonusModal",
  components: {},
  data() {
    return {
      redeemCheck: false,
      redeemBonusResult: null,
      isLoading: false
    };
  },
  props: {
    isRedeemBonusModalActive: {
      type: Boolean,
      default: false,
    },
    bonus: {
      type: Object,
      default: null,
    },
  },
  methods: {
    closeModal(){
      this.redeemCheck = false;
      this.redeemBonusResult = null;
      this.$emit('close');
    },
    redeemBonus() {
      this.isLoading = true;
      const params = {
        bonus_id: this.bonus.id,
      };

      this.$store.getters.axios
        .post(
          this.$store.getters.apiUrl + "/bonuses/redeem",
          {},
          { params: params }
        )
        .then((response) => {
          this.redeemBonusResult = response.data;

          this.$emit('success', this.redeemBonusResult)
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {},
};
</script>
