import { userService } from "../_services";
import { router } from "../main";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, { username, password, redirectUrl = null }) {
      commit("loginRequest", { username });

      userService.login(username, password).then(
        (user) => {
          commit("loginSuccess", user);

          if (redirectUrl == null) router.push("/");
          else {
            if (router.currentRoute.path != redirectUrl) {
              router.push(redirectUrl);
            }
          }
        },
        (error) => {
          commit("loginFailure", error);
        }
      );
    },
    logout({ commit }) {
      userService.logout();
      commit("logout");
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = { error: true };
      state.user = null;
    },
    logout(state) {
      state.status = { loggedout: true };
      state.user = null;
    },
  },
};
