import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import App from "./App.vue";
import Buefy from "buefy";
import { store } from "./store/store.js";
import Home from "./components/Home";
import Card from "./components/Card";
import Login from "./components/Login";
import Imprint from "./components/Imprint";
import PrivacyPolicy from "./components/PrivacyPolicy";
import NoCardHome from "./components/NoCardHome";
import LoyalityPoints from "./components/LoyalityPoints";
import Transactions from "./components/Transactions";
import Recharge from "./components/Recharge";
import Completion from "./components/Completion";
import TransactionDetails from "./components/TransactionDetails";
import VueQRCodeComponent from "vue-qrcode-component";

Vue.use(Buefy);

Vue.use(Vuex);
Vue.use(VueRouter);

Vue.component("qr-code", VueQRCodeComponent);

Vue.config.productionTip = false;

export const router = new VueRouter({
  mode: "history",  
  routes: [
    {
      path: "/card/:id",
      name: "card",
      component: Card,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/transactions/:id",
      name: "transactions",
      component: Transactions,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/transactions/:id/:paymentId",
      name: "paymentdetails",
      component: TransactionDetails,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/recharge/:id",
      name: "recharge",
      component: Recharge,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/loyalitypoints",
      name: "loyalitypoints",
      component: LoyalityPoints,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/completion/:id",
      name: "completion",
      component: Completion,
      meta: {
        authRequired: true,
      },
    },
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/imprint",
      name: "imprint",
      component: Imprint,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyPolicy,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        authRequired: false,
      },
    },
    {
      path: "*",
      component: NoCardHome,
      meta: {
        authRequired: false,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = to.meta.authRequired;
  const loggedIn = localStorage.getItem("user");

  if (authRequired && !loggedIn) {
    return next("/login");
  }

  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
